import logo from "./logo.svg";
import "./App.css";
import Hero from "./pages/Hero/Hero";
import Services from "./pages/Services/Services";
import Fleet from "./pages/Fleet/Fleet";
import Reservation from "./pages/Reservation/Reservation";
import Footer from "./pages/Footer/Footer";
import Navigation from "./components/Navigation/Navigation";
import TestimonialSlider from "./pages/Testemonial/Testemonial";
import About from "./pages/About/About";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Hero />
      <About />
      <Services />
      <Fleet />
      <Reservation />
      <TestimonialSlider />
      <Footer />
    </div>
  );
}

export default App;
