import React, { useRef, useState } from "react";
import "./Reservation.css";
import reservationImage from "./book-bg.jpg"; // Background image
import emailjs from "@emailjs/browser";

const Reservation = () => {
  const [step, setStep] = useState(1); // Track the form step
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    fromLocation: "",
    toLocation: "",
    time: "",
    date: "",
    passengers: "",
    vehicleType: "",
  });

  const [errors, setErrors] = useState({}); // Store validation errors

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_mn06bof",
        "template_ekzjhuo",
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: formData.phoneNumber,
          email: formData.email,
          fromLocation: formData.fromLocation,
          toLocation: formData.toLocation,
          time: formData.time,
          date: formData.date,
          passengers: formData.passengers,
          vehicleType: formData.vehicleType,
        },
        "H4uveL-JslqYOT-Zq"
      )
      .then(
        (result) => {
          // Email sent successfully, show the alert
          window.alert(
            "Reservation confirmed! Your email has been sent successfully."
          );

          // Optionally reset form data or redirect the user after success
          setFormData({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            fromLocation: "",
            toLocation: "",
            time: "",
            date: "",
            passengers: "",
            vehicleType: "",
          });
          setStep(1); // Reset to step 1 if you want to start fresh
        },
        (error) => {
          // If the email failed, show an error alert
          window.alert(`Failed to send the reservation. Error: ${error.text}`);
        }
      );
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validate if all required fields are filled
  const validateStep1 = () => {
    let step1Errors = {};
    if (!formData.firstName) step1Errors.firstName = "First name is required";
    if (!formData.lastName) step1Errors.lastName = "Last name is required";
    if (!formData.phoneNumber)
      step1Errors.phoneNumber = "Phone number is required";
    if (!formData.email) step1Errors.email = "Email is required";

    setErrors(step1Errors);
    return Object.keys(step1Errors).length === 0;
  };

  const validateStep2 = () => {
    let step2Errors = {};
    if (!formData.fromLocation)
      step2Errors.fromLocation = "From location is required";
    if (!formData.toLocation)
      step2Errors.toLocation = "To location is required";
    if (!formData.date) step2Errors.date = "Date is required";
    if (!formData.time) step2Errors.time = "Time is required";
    if (!formData.passengers)
      step2Errors.passengers = "Number of passengers is required";
    if (!formData.vehicleType)
      step2Errors.vehicleType = "Vehicle type is required";

    setErrors(step2Errors);
    return Object.keys(step2Errors).length === 0;
  };

  const handleNextStep = () => {
    if (step === 1 && validateStep1()) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 2 && validateStep2()) {
      sendEmail(e); // Call sendEmail only on the last step if validation passes
    }
  };

  return (
    <div
      id="reservation"
      className="reservation-container"
      style={{ backgroundImage: `url(${reservationImage})` }}
    >
      <div className="reservation-overlay">
        <h2 className="reservation-title">Reserve Your Ride</h2>
        <form className="reservation-form" ref={form} onSubmit={handleSubmit}>
          {step === 1 && (
            <div className="step">
              <h3>Personal Information</h3>
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  required
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName}</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                  required
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName}</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  required
                />
                {errors.phoneNumber && (
                  <span className="error">{errors.phoneNumber}</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  required
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <button
                type="button"
                className="next-btn"
                onClick={handleNextStep}
              >
                Next
              </button>
            </div>
          )}

          {step === 2 && (
            <div className="step">
              <h3>Ride Information</h3>
              {/* Hidden inputs to carry over step 1 form data */}
              <input
                type="hidden"
                name="firstName"
                value={formData.firstName}
              />
              <input type="hidden" name="lastName" value={formData.lastName} />
              <input
                type="hidden"
                name="phoneNumber"
                value={formData.phoneNumber}
              />
              <input type="hidden" name="email" value={formData.email} />

              <div className="form-group">
                <input
                  type="text"
                  name="fromLocation"
                  value={formData.fromLocation}
                  onChange={handleChange}
                  placeholder="From"
                  required
                />
                {errors.fromLocation && (
                  <span className="error">{errors.fromLocation}</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="toLocation"
                  value={formData.toLocation}
                  onChange={handleChange}
                  placeholder="To"
                  required
                />
                {errors.toLocation && (
                  <span className="error">{errors.toLocation}</span>
                )}
              </div>
              <div style={{ display: "flex", width: "100%", gap: "50px" }}>
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="form-group"
                >
                  <span>Date</span>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                  {errors.date && <span className="error">{errors.date}</span>}
                </div>
                <div style={{ width: "20%" }} className="form-group">
                  <span>Time</span>
                  <input
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    required
                  />
                  {errors.time && <span className="error">{errors.time}</span>}
                </div>
              </div>
              <div className="form-group">
                <select
                  name="passengers"
                  value={formData.passengers}
                  onChange={handleChange}
                  required
                >
                  <option value="">Number of Passengers</option>
                  {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                {errors.passengers && (
                  <span className="error">{errors.passengers}</span>
                )}
              </div>
              <div className="form-group">
                <select
                  name="vehicleType"
                  value={formData.vehicleType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Vehicle Type</option>
                  <option value="SEDAN">SEDAN</option>
                  <option value="SUV_FULL_SIZE">SUV FULL SIZE</option>
                  <option value="SUV_SUBURBAN">SUV Suburban</option>
                  <option value="STRECHED_LIMO">STRECHED LIMO</option>
                </select>
                {errors.vehicleType && (
                  <span className="error">{errors.vehicleType}</span>
                )}
              </div>
              <div className="button-group">
                <button
                  type="button"
                  className="previous-btn"
                  onClick={handlePreviousStep}
                >
                  Previous
                </button>
                <button type="submit" className="submit-btn">
                  Reserve Now
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Reservation;
