import React from "react";
import "./Fleet.css";
import fleet from "./fleet-bg.jpg"; // The background image for the whole container
import { FaUser } from "react-icons/fa";
import { FaShoppingBag } from "react-icons/fa";

const fleetData = [
  {
    name: "Luxury Sedan",
    description:
      "Enjoy a luxurious and comfortable ride with our premium sedans. Ideal for business trips and airport transfers.",
    people: "3",
    bags: "3",
  },
  {
    name: "SUV Full Size",
    description:
      "Spacious and versatile, our SUVs offer a smooth ride and plenty of room for families or larger groups.",
    people: "6",
    bags: "6",
  },
  {
    name: "Suv Suburban",
    description:
      "Our Suv Suburbans are perfect for group travel, providing ample space for luggage and passengers alike.",
    people: "7",
    bags: "6",
  },
  {
    name: "Streched Limo",
    description:
      "For corporate trips or VIP transportation, our executive Streched Limo provide top-tier comfort and style.",
    people: "10",
    bags: "6",
  },
];

const Fleet = () => {
  return (
    <div id="fleet" className="fleet-container">
      <h2 className="fleet-title">Our Fleet</h2>
      <div className="fleet-grid">
        {fleetData.map((vehicle, index) => (
          <div
            key={index}
            className="fleet-card"
            style={{ backgroundImage: `url(${vehicle.image})` }}
          >
            <div className="fleet-overlay">
              <h3 className="fleet-name">{vehicle.name}</h3>
              <p className="fleet-description">{vehicle.description}</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <p className="fleet-description">
                  <span style={{ marginRight: "5px" }}>
                    <FaUser size={18} />
                  </span>
                  {vehicle.people}
                </p>
                <p className="fleet-description">
                  <span style={{ marginRight: "5px" }}>
                    <FaShoppingBag size={18} />
                  </span>
                  {vehicle.bags}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fleet;
