import React from "react";
import "./Hero.css"; // Ensure this CSS file is included
import { IoCalendar } from "react-icons/io5";
import { IoCarSportOutline } from "react-icons/io5";
import { AiOutlineSafety } from "react-icons/ai";
import { IoManOutline } from "react-icons/io5";

const HeroSection = () => {
  return (
    <div id="home" className="hero-container">
      {/* <img src={heroImg} /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="content">
          <h1 className="nyc-limo-service">
            New Jersey's best Limo & Car Service
          </h1>

          <button className="hero-button">
            <a
              href="#reservation"
              className="button-link"
              style={{ textDecoration: "none" }}
            >
              Reserve Now
            </a>
          </button>
        </div>
        <div className="hero-info">
          <div className="hero-info-card">
            <h3 className="hero-info-card-header">Online Booking</h3>
            <span>
              <IoCalendar className="hero-info-icon" />
            </span>
            <p className="hero-info-card-desc">
              Seamless scheduling, hassle-free reservations.
            </p>
          </div>
          <div className="hero-info-card">
            <h3 className="hero-info-card-header">Expirienced Driver</h3>
            <span>
              <IoManOutline className="hero-info-icon" />
            </span>
            <p className="hero-info-card-desc">
              Experienced chauffeurs, reliable service.
            </p>
          </div>
          <div className="hero-info-card">
            <h3 className="hero-info-card-header">Safety Rides</h3>
            <span>
              <AiOutlineSafety className="hero-info-icon" />
            </span>
            <p className="hero-info-card-desc">
              Secure journeys, peace of mind.
            </p>
          </div>
          <div className="hero-info-card">
            <h3 className="hero-info-card-header">Car Quality</h3>
            <span>
              <IoCarSportOutline className="hero-info-icon" />
            </span>
            <p className="hero-info-card-desc">
              Premium vehicles, superior comfort.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
