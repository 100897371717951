import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testemonial.css";

const testimonials = [
  {
    text: "The limo arrived on time and the chauffeur was incredibly professional. It made our anniversary even more special. Highly recommend this family-run service!",
    author: "Emily Davis",
    rating: 5,
  },
  {
    text: "Fantastic experience! The ride was smooth, and the attention to detail was exceptional. Will definitely book again for future events.",
    author: "Mark Johnson",
    rating: 5,
  },
  {
    text: "Excellent service from start to finish. The staff was friendly, and the limo was spotless. A true luxury experience!",
    author: "Rebecca White",
    rating: 5,
  },
  {
    text: "The best limo service we've ever used. Our driver was prompt, courteous, and made sure we arrived at our event in style. Absolutely loved it!",
    author: "James Taylor",
    rating: 5,
  },
  {
    text: "We booked the limo for a wedding, and everything was perfect. The driver was so kind and patient, and the vehicle was gorgeous. Couldn't have asked for more!",
    author: "Sarah Anderson",
    rating: 5,
  },
];

const TestimonialSlider = () => {
  const settings = {
    dots: true, // Show dots for navigation
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides on larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 slides for screens 1024px and below
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 slide for screens 600px and below
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderStars = (rating) => {
    return Array.from({ length: rating }, (_, i) => (
      <span key={i} className="star">
        ★
      </span>
    ));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h1>Testimonials</h1>
      <div className="slider-container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="card">
              <p className="testimonial-text">"{testimonial.text}"</p>
              <p className="testimonial-author">- {testimonial.author}</p>
              <div className="stars">{renderStars(testimonial.rating)}</div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialSlider;
