import React, { useState } from "react";
import "./Navigation.css";
import { FaBars, FaTimes } from "react-icons/fa"; // Icons for the hamburger and close
import { FaCar } from "react-icons/fa";
const Navigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="nav-container">
      <div
        className="nav-logo-div"
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <FaCar color="black" size={30} />
        <div className="nav-logo">Prestige Limo & Car Service </div>
      </div>
      {/* Navigation Links */}
      <ul className={`nav-links ${isMobileMenuOpen ? "active" : ""}`}>
        <li>
          <a href="#home" onClick={toggleMenu}>
            Home
          </a>
        </li>
        <li>
          <a href="#services" onClick={toggleMenu}>
            Services
          </a>
        </li>
        <li>
          <a href="#fleet" onClick={toggleMenu}>
            Fleet
          </a>
        </li>
        <li>
          <a href="#reservation" onClick={toggleMenu}>
            Reservation
          </a>
        </li>
      </ul>

      {/* Hamburger icon */}
      <div className="nav-toggle" onClick={toggleMenu}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navigation;
