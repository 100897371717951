import React from "react";
import "./Services.css";
import { FaPlaneDeparture, FaBus, FaRing, FaBriefcase } from "react-icons/fa";
import wedding from "./wedding.jpg";
import airport from "./airport.jpg";
import business from "./business.jpg";
import intercity from "./intercity.jpg";

const Services = () => {
  const servicesData = [
    {
      icon: <FaPlaneDeparture />,
      title: "Airport Transfers",
      description:
        "Convenient and reliable transfers to and from the airport. Sit back and enjoy a stress-free journey to your destination.",
      img: airport,
    },
    {
      icon: <FaBus />,
      title: "Intercity Trips",
      description:
        "Travel comfortably between cities with our easy booking service. We provide the best routes for your convenience.",
      img: intercity,
    },
    {
      icon: <FaRing />,
      title: "Wedding Events",
      description:
        "Make your special day even more memorable with our dedicated transport services for weddings.",
      img: wedding,
    },
    {
      icon: <FaBriefcase />,
      title: "Business Meetings",
      description:
        "Arrive on time and in style at your business meetings. Our service ensures you can prepare on the go without any stress.",
      img: business,
    },
  ];

  return (
    <div id="services" className="services-container">
      <h2 className="services-title">Never miss something important with us</h2>
      <div className="services-cards">
        {servicesData.map((service, index) => (
          <div key={index} className="card">
            <div
              className="card-image"
              style={{ backgroundImage: `url(${service.img})` }}
            ></div>
            <div className="card-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
