import React from "react";
import "./About.css"; // Assuming you're adding styles in a separate CSS file
import about from "./about.png";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-image">
        {/* You can replace the source with your own image */}
        <img src={about} alt="Limo Service" />
      </div>
      <div className="about-content">
        <h2>About Our Family-Owned Limo Service</h2>
        <p>
          Welcome to our family-run limo service! With many years of experience,
          we take pride in delivering top-notch, reliable, and luxurious
          transportation services. Our commitment to customer satisfaction is a
          reflection of our family's values. We treat every client like family,
          ensuring that you have a comfortable and unforgettable experience.
        </p>
      </div>
    </div>
  );
};

export default About;
